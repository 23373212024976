import React from 'react'
import Layout from '../components/Layout'
import firebase from 'firebase/app'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { navigate } from 'gatsby'
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { authenticateUser } from '../state/actions/auth';

class Signin extends React.Component {
  state = {
    email: '',
    pw: '',
    errorMsg: '',
    error: false,
    verifying: false,
    validationError: false,
    success: false,
  }

  updateField(event, field) {
    const isEmail = field === 'email';
    if (isEmail) {
      const keyCode = event.keyCode;
      const isReturnKey = keyCode === 13;

      if (!isReturnKey) {
        const value = event.target.value;
        this.setState({ [field]: value });
      }

      if (isReturnKey) {
        () => this.submit();
      }
    }
  }

  submit = () => {
    return async () => {
      const hasError = this.state.validationError !== false
      const hasWindow = typeof window !== undefined;
      this.setState({ verifying: true, error: false })
      if (!hasError && hasWindow) {
        const email = this.state.email
        const validateUser = firebase
          .functions()
          .httpsCallable(process.env.VALIDATE_USER);
        const response = await validateUser({email})
        const hasUser = response.data
        this.setState({ verifying: false })

        if (hasUser) {
          const actionCodeSettings = {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be whitelisted in the Firebase Console.
            url: process.env.SITE,
            // This must be true.
            handleCodeInApp: true,
          };

          firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
            .then(() => {
              // The link was successfully sent. Inform the user.
              // Save the email locally so you don't need to ask the user for it again
              // if they open the link on the same device.
              localStorage.setItem('emailForSignIn', email);
              this.setState({ success: true, errorMsg: 'Verification Email Sent.' })
            })
            .catch((error) => {
              // Some error occurred, you can inspect the code: error.code
              console.log(error)
              this.setState({ error: true, errorMsg: error.message })
            });
        } else {
          this.setState({ error: true, errorMsg: 'No account exists' })
        }
      } else {
        const email = this.state.email
        const validateUser = firebase.functions().httpsCallable('validateUser')
        const response = await validateUser({email})
        const hasUser = response.data
      
        if (hasUser && hasWindow) {
          firebase.auth().signInWithEmailLink(email, window.location.href)
            .then(async () => {
              await this.authenticateUser(email);
              // Clear email from storage.
              
              // You can access the new user via result.user
              // Additional user info profile not available via:
              // result.additionalUserInfo.profile == null
              // You can check if the user is new or existing:
              // result.additionalUserInfo.isNewUser
            })
            .catch((error) => {
              // Some error occurred, you can inspect the code: error.code
              // Common errors could be invalid email and invalid or expired OTPs.
              this.setState({ error: true, errorMsg: error.message })
            });
        } else {
          console.log('No Account Exists')
          this.setState({ error: true, errorMsg: 'No account exists' })
        }

      }
    }

  }

  keyPressSignIn = (event: any) => {
    const keyCode = event.keyCode;
    const isReturnKey = keyCode === 13;
    if (isReturnKey) {
      () => this.submit();
    }
  };

  private async authenticateUser(email: string) {
    const firestore = firebase.firestore();
    const userDB = firestore.collection(process.env.USERS);
    const today = new Date();
    let user = null;
    try {
      const results = await userDB.where("email", "==", email).get();
      results.forEach(doc => (user = doc.data()));
      const hasUser = user !== null;
      if (hasUser) {
        localStorage.removeItem("emailForSignIn");
        const productExp = new Date(user.productExp * 1000);
        const isActive = today < productExp;
        if (isActive) {
          localStorage.setItem(process.env.TOKEN_ID, `${user.productExp}`);
          navigate('/app');
        }
        if (!isActive) {
          navigate('/account');
        }
      }
    }
    catch (error) {
      this.setState({ error: true, errorMsg: 'An Error Occured. Please Try Again.' });
    }
  }

  async componentDidMount() {
    if (firebase.auth().isSignInWithEmailLink(window.location.href) && typeof window !== undefined) {
      let email = window.localStorage.getItem('emailForSignIn');
      this.setState({ verifying: true })
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.

      if (email === null) {
        this.setState(
          {
            error: true,
            errorMsg: 'Looks Like You\'re Signing In On Another Device Or Browser. Please Provide Your Email Once Again.',
            verifying: false
          }
        )
      } else {
        // The client SDK will parse the code from the link for you.
        firebase.auth().signInWithEmailLink(email, window.location.href)
          .then(() => {
            this.authenticateUser(email);
          })
          .catch((error) => {
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
            this.setState({ validationError: true, errorMsg: error.message })
          });
      }

    }

    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        // User is signed in.
        navigate('/')
      }
    });
  }

  render() {
    if (this.state.verifying) {
      const content = (
        <Paper className="signup">
          <Typography variant="h4">Verifying Account</Typography>
          <CircularProgress className="signup__verify" color="secondary" />
        </Paper>
      )
      return (
        <Layout components={[{ columns: 12, content, className: 'sign-in' }]} />
      )
    } else {
      const error = this.state.error
      const success = this.state.success
      const errorMsg = this.state.errorMsg
      const content = (
        <Paper className="signup">
          <Typography variant="h4">Sign In</Typography>
          {error || success ? (
            <Typography variant="h6">{errorMsg}</Typography>
          ) : null}
          <div className="signup__form">
            <TextField
              id="outlined-email-input"
              label="Email"
              type="email"
              name="email"
              autoComplete="email"
              margin="normal"
              variant="outlined"
              className="signup__field"
              onChange={event => this.updateField(event, "email")}
              onKeyUp={event => this.keyPressSignIn(event)}
            />
            <Button variant="flat" onClick={() => navigate('/join')}>
              Don't have an account? Sign Up
            </Button>
            <div className="signup__buttons">
              <Button
                color="primary"
                variant="contained"
                onClick={this.submit()}
              >
                Sign In
              </Button>
              {/* <Button color="secondary" variant="contained" onClick={() => signinWithGoogle()}>Sign In With Google</Button> */}
            </div>
          </div>
        </Paper>
      );
      return (
        <Layout 
          components={[{ columns: 12, content, className: 'sign-in' }]}
          authRequired={false}
        />
      )
    }
  }
}

const mapStateToProps = (state: any) => ({
  isActive: state.auth.isActive
})

export default connect(mapStateToProps, { authenticateUser })(Signin);